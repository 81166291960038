import React from "react"
import useTranslations from "../../../utils/useTranslations"
import ComparisonPanels from "../../../components/comparisonPanels"
import LocalizedLink from "../../../components/localizedLink"
import Logo from "../../../components/logo"


const BittorrentClassicCompare = ({ pageContext: {text, pageName, locale} }) => {
const t = useTranslations(text)

const logoTitle = <>
	<div className="font-weight-normal text-nowrap">Compare <div className="compare-page-logo-container"><Logo fsCoefficient="17" fsMax="27" color="black" tag="p" productName="Classic" className="compare-page-logo"/></div> Versions</div>
</>
const titleContent = (locale === "en") ? logoTitle : t("Compare BitTorrent Classic Versions")


  return (
  	<div className="mt-5 pt-5">
	    <div className={"container"}>
	      <h1 className="h2 text-center mb-5">{ titleContent }</h1>
	      <ComparisonPanels pageContext={{ "text":text, "pageName":pageName }}>
			    <p className="text-center mt-5">
			      	<LocalizedLink to="/products/win/bittorrent-classic-premium" id="products-win-classic-compare-premium-learnmore" className="font-weight-bold">
			      		{t(`Learn more`)}
			      	</LocalizedLink> {t(`about BitTorrent Classic premium products`)}
				</p>
	      </ComparisonPanels>
	    </div>
	</div>
  )
}

export default BittorrentClassicCompare